import { Result } from '../../../../types'
import { AxiosError } from 'axios'
import ApiClient from '../../../../utils/api/ApiClient'
import { AustralianState } from '../../../../types'
import { Clinic } from '../../../../types'
import { Patient } from '../../../../types'
import { Prescriber } from '../../../../types'
import { PrescribedItem } from '../../../../types'
import {
  RtpmPreCheckClinic,
  RtpmPreCheckDrug,
  RtpmPreCheckPatient,
  RtpmPreCheckPrescriber,
  RtpmPreCheckRequest,
} from './rtpmPrecheckRequest'
import { ErrorCodes } from '../../../../types/errors'

export interface RTPMResult {
  code: number
  alertDescription: string
  contextualLinkUrl: string
}

export const rtpmPrecheck = async (request: {
  patient: Patient
  clinic: Clinic
  prescriber: Prescriber
  prescribedItems: PrescribedItem[]
}): Promise<Result<RTPMResult>> => {
  try {
    const transformedRequest = transformToRtpmPrecheckRequest(request)
    const { data } = await ApiClient.post('bff/rtpm/precheck', transformedRequest)
    return { data: data.data }
  } catch (error: unknown) {
    const axiosError = error as AxiosError
    if (axiosError.response) {
      return {
        error: {
          code: axiosError.code,
          message: axiosError.message,
          status: axiosError.response.status,
        },
      }
    } else if (axiosError.request) {
      return {
        error: {
          code: ErrorCodes.NETWORK_CONNECTION_ERROR,
          message:
            'Cannot connect to Prescription Monitoring. Please log into the Prescription Monitoring portal and search for the patient.',
        },
      }
    } else {
      return { error: { code: ErrorCodes.GENERAL_ERROR, message: `${error}` } }
    }
  }
}

const transformToRtpmPrecheckRequest = ({
  patient,
  clinic,
  prescriber,
  prescribedItems,
}: {
  patient: Patient
  clinic: Clinic
  prescriber: Prescriber
  prescribedItems: PrescribedItem[]
}): RtpmPreCheckRequest => ({
  patient: transformPatient(patient),
  clinic: transformClinic(clinic),
  prescriber: transformPrescriber(prescriber),
  drugs: prescribedItems.map((item) => transformDrugs(item, patient)),
})

const transformPatient = (patient: Patient): RtpmPreCheckPatient => ({
  familyName: patient.family_name,
  givenName: patient.given_names,
  dob: patient.date_of_birth,
  gender: ['M', 'F'].includes(patient.gender) ? (patient.gender as 'M' | 'F') : undefined,
  ihi: parseInt(patient.ihi_number),
  medicareNumber: `${patient.medicare_no}${patient.medicare_irn}`,
  concessionNumber: patient.concession_pension_no,
  dvaNumber: patient.dva_file_no,
  dvaColour: patient.dva_card_colour,
  ctg: patient.ctg_flag ?? false,
  mobile: patient.phone_number,
  email: patient.email,
  address1: patient.address_1,
  address2: patient.address_2,
  suburb: patient.suburb,
  postCode: `${patient.postcode}`,
  state: patient.state as AustralianState,
  hospitalInpatient: patient.hospital_category ?? false,
})

const transformClinic = (clinic: Clinic): RtpmPreCheckClinic => ({
  name: clinic.name,
  hpio: parseInt(clinic.hpio_number),
  email: clinic.email,
  address1: clinic.address_1,
  address2: clinic.address_2,
  suburb: clinic.suburb,
  postCode: clinic.postcode,
  state: clinic.state as AustralianState,
})

const transformPrescriber = (prescriber: Prescriber): RtpmPreCheckPrescriber => ({
  familyName: prescriber.familyName,
  givenName: prescriber.givenNames,
  prescriberType: prescriber.prescriberType as 'M' | 'E' | 'U' | 'F' | 'D' | 'V' | 'T',
  ahpra: prescriber.ahpraNumber,
  prescriberNo: prescriber.prescriberNumber,
  providerNo: prescriber.providerNumber || '',
  hpii: prescriber.hpii,
  // phone: prescriber.phoneNumber,
  // mobile: prescriber.phoneNumber,
  // fax: prescriber.faxNumber,
  email: prescriber.email,
})

const transformDrugs = (drug: PrescribedItem, patient: Patient): RtpmPreCheckDrug => ({
  repeatInterval: drug.repeatIntervals,
  numberOfRepeatsAllowed: parseInt(drug.numberOfRepeatsAuthorised as string),
  prescriberScriptNumber: parseInt(drug.prescriberScriptNumber),
  routeOfAdmin: drug.routeAdministration,
  authorityNumber: drug.pbsDvaAuthorityNumber,
  phoneApprovalAuthorityNumber: drug.phoneApprovalAuthorityNumber,
  extempType: drug.isExtemp ?? false,
  extempDescription: drug.extemporaneousDescription,
  tradeName: drug.tradeName,
  genericName: drug.genericName,
  strength: drug.strength,
  form: drug.form,
  pbsCode: drug.pbsCode,
  manufacturerCode: drug.pbsManufacturerCode,
  quantity: drug.quantity,
  instruction: drug.patientInstructions,
  patientCategory: patient.hospital_category ?? false,
  // amtMPCode?: number
  // amtMPPCode?: number
  // amtMPUUCode?: number
  amtTPCode: drug.productDetails?.amt?.[0]?.tpCode
    ? parseInt(drug.productDetails?.amt?.[0]?.tpCode)
    : undefined,
  // amtTPPCode?: number
  // amtTPUUCode?: number
  // amtCTPPCode?: number
})
