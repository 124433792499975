import { RTPMResult } from './services/rtpmPrecheckService'
import { CheckCircleIcon, InfoIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  CloseButton,
  Flex,
  Link,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

interface RTPMNotificationConfig {
  status: 'info' | 'warning' | 'success' | 'error' | 'loading'
  icon: any
  title: string
  variant: 'subtle' | 'left-accent' | 'top-accent' | 'solid'
}

export const RTPMNotification = ({
  data,
  error,
  onClose,
  onCheck,
}: {
  data?: RTPMResult | null
  error?: any
  onCheck?: () => void
  onClose?: () => void
}) => {
  const { isOpen: isVisible, onClose: onAlertClose } = useDisclosure({ defaultIsOpen: true })
  if (!data && !error) {
    return (
      <Center p={4}>
        <Flex alignItems="center" gap={2}>
          <Spinner size="sm" color="blue.500" />
          <Text color="gray.600">Checking RTPM status...</Text>
        </Flex>
      </Center>
    )
  }
  let config: RTPMNotificationConfig | null = null
  let description = ''
  let contextualLink = ''
  const getAlertConfig = (code: number): RTPMNotificationConfig | null => {
    switch (code) {
      case 0:
        return {
          status: 'success',
          icon: CheckCircleIcon,
          title: 'RTPM Check Passed',
          variant: 'solid',
        }
      case 1:
        return {
          status: 'warning',
          icon: WarningIcon,
          title: 'RTPM Warning',
          variant: 'solid',
        }
      case 2:
        return {
          status: 'error',
          icon: WarningTwoIcon,
          title: 'RTPM Alert',
          variant: 'solid',
        }
      default:
        return null
    }
  }
  if (error) {
    config = {
      status: 'info',
      icon: InfoIcon,
      title: 'RTPM',
      variant: 'solid',
    }
    description =
      'Cannot connect to Prescription Monitoring. Please log into the Prescription Monitoring portal and search for the patient.'
    contextualLink = ''
  }

  if (data) {
    const { code, alertDescription, contextualLinkUrl } = data
    config = getAlertConfig(code)
    description = alertDescription
    contextualLink = contextualLinkUrl
  }

  if (!config) return null

  return isVisible ? (
    <Alert
      status={config.status}
      variant={config.variant}
      borderRadius="md"
      alignItems="flex-start"
      mb={'4'}
      bgColor={error ? 'gray.400' : undefined}
    >
      <AlertIcon as={config.icon} boxSize="20px" mt="2px" />
      <Box flex="1">
        <AlertTitle fontSize="md" fontWeight="bold">
          {config.title}
        </AlertTitle>
        <AlertDescription display="block">
          {description}
          {contextualLink ? (
            <Text>
              {' '}
              Click{' '}
              <Link href={contextualLink} textDecoration="underline" isExternal onClick={onCheck}>
                HERE
              </Link>{' '}
              to view details.
            </Text>
          ) : null}
        </AlertDescription>
      </Box>
      {onClose && error && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => {
            onClose()
            onAlertClose()
          }}
        />
      )}
    </Alert>
  ) : null
}
