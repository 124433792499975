interface WindowsWithIntercom extends Window {
  Intercom?: (command: string, options: Record<string, unknown>) => void
}

interface IntercomOptions {
  user?: {
    name: string
    email: string
  }
  disable?: boolean
}

export const enableIntercomService = (options?: IntercomOptions) => {
  const Intercom = (window as unknown as WindowsWithIntercom)?.Intercom
  if (!Intercom) {
    console.error('The Intercom is not enabled.')
    return
  }
  if (options?.disable) {
    try {
      Intercom('shutdown', { app_id: 'y2ner8p3' })
      console.error('Intercom has been disabled')
    } catch (error) {
      console.error('Error while disabling Intercom:', error)
    }
    return
  }

  if (options && options.user) {
    Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'y2ner8p3',
      name: options?.user.name,
      email: options?.user.email,
    })
  }
  Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'y2ner8p3',
  })
}
