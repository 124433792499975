import {
  Button,
  Center,
  Checkbox,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { SyntheticEvent } from 'react'
import { CreateAndUpdateCustomPrescriptionItemModal } from './CreateCustomPrescriptionItemModal'
import { CustomPrescriptionItem } from './CustomPrescriptionItem'
import { useAppSelector } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'

interface CustomPrescriptionItemTableProps {
  customPrescriptionItems: CustomPrescriptionItem[]
  handleUpdatePrescriptionItem: (values: any, id?: string) => Promise<void>
  handleDeletePrescriptionItem: (prescriptionItemId: string, e: SyntheticEvent) => void
  selectedItemId: string | null
  setSelectedItemId: (id: string | null) => void
  isOnlyPrivateMedicationAllowed: boolean
}

const CustomPrescriptionItemTable: React.FC<CustomPrescriptionItemTableProps> = ({
  customPrescriptionItems,
  handleUpdatePrescriptionItem,
  handleDeletePrescriptionItem,
  selectedItemId,
  setSelectedItemId,
  isOnlyPrivateMedicationAllowed,
}) => {
  const toast = useToast()
  const selectedPatient = useAppSelector(
    ({ patientDetail }: RootState) => patientDetail.selectedPatient
  )
  if (customPrescriptionItems.length < 1) {
    return (
      <Center>
        <Heading size="xs" textTransform="uppercase">
          No Custom Prescription Items
        </Heading>
      </Center>
    )
  }

  return (
    <Table size="sm" mb={6}>
      <Thead>
        <Tr>
          <Th></Th>
          <Th>Drug Details</Th>
          <Th>Quantity</Th>
          <Th>Repeats</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {customPrescriptionItems.map((drug) => {
          const isDisabled = !!(drug.pbsCode && isOnlyPrivateMedicationAllowed)
          return (
            <Tr
              key={drug.id}
              _hover={{
                bgColor: 'gray.100',
              }}
              bgColor={selectedItemId === drug.id ? 'gray.200' : 'white'}
              onClick={() => {
                if (isDisabled) {
                  toast({
                    title: 'Warning',
                    description: `No Medicare or DVA number detected for ${selectedPatient.given_names} ${selectedPatient.family_name}. Selection of non-private medications is disabled.`,
                    status: 'warning',
                    duration: null,
                    isClosable: true,
                    position: 'top',
                  })
                  return
                } else {
                  setSelectedItemId(selectedItemId === drug.id ? null : drug.id)
                }
              }}
            >
              <Td>
                <Checkbox
                  inputProps={{
                    onClick: (e) => e.stopPropagation(),
                  }}
                  isChecked={selectedItemId === drug.id}
                  disabled={isDisabled}
                />
              </Td>
              <Td>
                <Heading size="xs" textTransform="uppercase">
                  {drug.compoundName}
                </Heading>
                <Text size="xs">{`${drug.itemStrength} ${drug.dosageForm}`}</Text>
              </Td>
              <Td>{drug.quantity ? drug.quantity : ''}</Td>
              <Td>{drug.repeats ? drug.repeats : ''}</Td>
              <Td>
                <CreateAndUpdateCustomPrescriptionItemModal
                  modalHeader="Update Custom Prescription Item"
                  modalFooter="Update"
                  submit={handleUpdatePrescriptionItem}
                  prescriptionItem={drug}
                >
                  {({ openModal }) => (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal()
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </CreateAndUpdateCustomPrescriptionItemModal>
                <Button variant="link" onClick={(e) => handleDeletePrescriptionItem(drug.id, e)}>
                  Delete
                </Button>
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default CustomPrescriptionItemTable
