import { FC, FunctionComponent, SyntheticEvent, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  VStack,
} from '@chakra-ui/react'
import { CreateAndUpdateCustomPrescriptionItemModal } from './CreateCustomPrescriptionItemModal'
import { CustomPrescriptionItem } from './CustomPrescriptionItem'
import {
  useDeleteCustomPrescriptionItemMutation,
  useGetCustomPrescriptionItemsQuery,
  useUpdateCustomPrescriptionItemMutation,
  CreateCustomPrescriptionItemReq,
  useCreateCustomPrescriptionItemMutation,
} from '../../../services/customPrescriptionItems'
import CustomPrescriptionItemTable from './CustomPrescriptionItemTable'
import Pagination from 'rc-pagination'
import SearchInput from '../../../components/form/SearchInput'
import { useAppSelector } from '../../../app/typedReduxHooks'

interface CustomPrescriptionModalProps {
  onSelect: (customPrescriptionItem: CustomPrescriptionItem) => void
  children: FunctionComponent<{
    openModal: () => void
  }>
  isOnlyPrivateMedicationAllowed: boolean
}

const PAGE_LIMIT = 6

export const CustomPrescriptionItemModal: FC<CustomPrescriptionModalProps> = ({
  onSelect,
  children: ChildrenComponent,
  isOnlyPrivateMedicationAllowed,
}) => {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const { prescribedItemList } = useAppSelector((state) => state.newScript)
  const { data: customPrescriptionItemsData } = useGetCustomPrescriptionItemsQuery({
    page,
    limit: PAGE_LIMIT,
    searchTerm,
  })

  const [updateCustomPrescriptionItem] = useUpdateCustomPrescriptionItemMutation()
  const [deleteCustomPrescriptionItem] = useDeleteCustomPrescriptionItemMutation()
  const [createCustomPrescriptionItem] = useCreateCustomPrescriptionItemMutation()

  const customPrescriptionItems = customPrescriptionItemsData?.data || []
  const [modalIsOpen, setModelIsOpen] = useState<boolean>(false)
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)

  useEffect(() => {
    const existedItem = prescribedItemList.filter((item) => item.productId === selectedItemId)[0]
    const selectedItem = customPrescriptionItems.filter((item) => item.id === selectedItemId)[0]
    const unableToSelect = isOnlyPrivateMedicationAllowed && selectedItem?.pbsCode
    setIsDisabled(!selectedItemId || !!existedItem || unableToSelect)
  }, [selectedItemId, prescribedItemList, customPrescriptionItemsData])

  const onSubmit = () => {
    const selectedItem = customPrescriptionItems.filter((item) => item.id === selectedItemId)[0]
    if (!selectedItem) {
      return
    }

    onSelect(selectedItem)
    setModelIsOpen(false)
  }

  const handleCreatePrescriptionItem = async (values: CreateCustomPrescriptionItemReq) => {
    await createCustomPrescriptionItem(values)
  }

  const handleUpdatePrescriptionItem = async (values: any, id?: string) => {
    if (id) {
      await updateCustomPrescriptionItem({ newPrescription: values, prescriptionItemId: id })
    }
  }

  const handleDeletePrescriptionItem = async (prescriptionItemId: string, e: SyntheticEvent) => {
    e.stopPropagation()
    await deleteCustomPrescriptionItem(prescriptionItemId)
  }

  const handleSearchCustomPrescription = (value: string) => {
    const newValue = value.trim()
    setSearchTerm(newValue)
    setPage(1)
  }

  return (
    <>
      <ChildrenComponent openModal={() => setModelIsOpen(true)} />
      <Modal
        isOpen={modalIsOpen}
        onClose={() => setModelIsOpen(false)}
        onCloseComplete={() => setSelectedItemId(null)}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent maxW="800px">
          <ModalHeader>Custom Prescription Items</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap="3" alignItems="flex-start">
              <SearchInput
                onSearch={handleSearchCustomPrescription}
                placeholder="prescription"
                w={['100%', '30%']}
              />
              <TableContainer>
                <CustomPrescriptionItemTable
                  handleUpdatePrescriptionItem={handleUpdatePrescriptionItem}
                  handleDeletePrescriptionItem={handleDeletePrescriptionItem}
                  customPrescriptionItems={customPrescriptionItems}
                  selectedItemId={selectedItemId}
                  setSelectedItemId={setSelectedItemId}
                  isOnlyPrivateMedicationAllowed={isOnlyPrivateMedicationAllowed}
                />
              </TableContainer>
              <Pagination
                showLessItems
                current={page}
                total={customPrescriptionItemsData?.meta?.totalItems}
                pageSize={PAGE_LIMIT}
                onChange={(currentPage) => setPage(currentPage)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="end">
            <CreateAndUpdateCustomPrescriptionItemModal
              submit={handleCreatePrescriptionItem}
              modalHeader="Create Custom Prescription Item"
              modalFooter="Create"
            >
              {({ openModal }) => (
                <Button colorScheme="blue" mr={3} onClick={() => openModal()}>
                  Create
                </Button>
              )}
            </CreateAndUpdateCustomPrescriptionItemModal>

            <Button colorScheme="blue" isDisabled={isDisabled} onClick={onSubmit}>
              Select
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
