// components/VisuallyDisabledButton.tsx
import { Button, ButtonProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface VisuallyDisabledButtonProps extends Omit<ButtonProps, 'disabled' | 'isDisabled'> {
  isVisuallyDisabled: boolean
  children: ReactNode
  onClick: () => void
}

const VisuallyDisabledButton = ({
  isVisuallyDisabled,
  onClick,
  children,
  colorScheme = 'blue',
  size = 'sm',
  ...props
}: VisuallyDisabledButtonProps) => {
  return (
    <Button
      colorScheme={colorScheme}
      size={size}
      opacity={isVisuallyDisabled ? 0.4 : 1}
      cursor={isVisuallyDisabled ? 'not-allowed' : 'pointer'}
      _hover={{
        opacity: isVisuallyDisabled ? 0.4 : 0.8,
      }}
      _active={{
        transform: isVisuallyDisabled ? 'none' : 'scale(0.98)',
        bg: isVisuallyDisabled ? `${colorScheme}.500` : `${colorScheme}.600`,
      }}
      _focus={{
        boxShadow: isVisuallyDisabled ? 'none' : 'outline',
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  )
}

export default VisuallyDisabledButton
