import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import Card from '../../../common/components/Card'
import {
  ProductResult,
  ProductSearchClient,
  resetProductResults,
  selectProduct,
  setProductResults,
  setSearchTerm,
} from '../ScriptForm/ProductSearchContextSlice'
import { deletePrescribedItem } from '../ScriptForm/PrescribedItem/PrescribedItemSlice'
import { DrugSearchResultsPanel } from '../DrugSearchResultsPanel'
import useApi from '../../../common/utils/api/useApi'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/form-control'
import {
  customPrescriptionItemToPrescribedItem,
  detailsToPrescribedItem,
} from '../ScriptForm/PrescribedItem'
import { PrescribedItem } from '../../../types'
import { CustomPrescriptionItemModal } from '../CustomPrescriptionItem/CustomPrescriptionItemModal'
import { isAbleToAddNewPrescribedItem } from '../utilities'
import { ADD_PRESCRIBED_ITEM_FAILED_ERROR, initFormError } from '../constants'
import MimsIntegrated from '../../../assets/mims-integrated.png'
import { preCheckProduct } from '../ScriptForm/PrescribedItem/utils/preCheckProduct'
import PrescribedItemDetails from './components/PrescribedItemDetails'
import { getPbsStatus } from './utils/getPbsStatus'
import PrescribedItemRow from './components/PrescribedItemRow'
import { PrescribedItemErrors } from '../../../types/prescription'
import { RootState } from '../../../app/store'

const DEFAULT_PAGE_INFO = { pageNumber: 1, pageOffset: 30 }

interface PrescriptionListProps {
  scid?: string
  prescribedItems: PrescribedItem[]
  selectedPrescribedItemIndex?: number
  toggleDrawer?: () => void
  onDelete?: (index: number) => void
  onAdd: (item: PrescribedItem) => any
  onSelect: (item: PrescribedItem, index?: number) => any
  onUpdate: (item: PrescribedItem, index: number) => any
  formErrors: PrescribedItemErrors
  setFormErrors: Dispatch<SetStateAction<PrescribedItemErrors>>
  isAbleToSelectS8Medicine: boolean
  isOnlyPrivateMedicationAllowed: boolean
}

const PrescribedItemList: FunctionComponent<PrescriptionListProps> = ({
  scid,
  toggleDrawer,
  onDelete,
  prescribedItems,
  selectedPrescribedItemIndex,
  onSelect,
  onAdd,
  onUpdate,
  formErrors,
  setFormErrors,
  isAbleToSelectS8Medicine,
  isOnlyPrivateMedicationAllowed,
}) => {
  const { productResults, productSearchTerm, selectedProduct } = useAppSelector(
    (state) => state.product
  )
  const { isPaperPrescription } = useAppSelector((state) => state.newScript)
  const isDeactivatedPrescriber: boolean = useAppSelector(
    ({ prescriber }: RootState) => !prescriber?.prescriber?.active
  )
  const [pageInfo, setPageInfo] = useState(DEFAULT_PAGE_INFO)
  const [hasMoreDrugs, setHasMoreDrugs] = useState(false)

  const selectedPrescribedItem = prescribedItems[selectedPrescribedItemIndex ?? -1]

  const dispatch = useAppDispatch()
  const toast = useToast()

  const { trigger: getProductDetails } = useApi(ProductSearchClient.getProductDetails, {
    onSuccess: (resp) => dispatch(selectProduct(resp.product)),
  })

  const [prescriptionError, setPrescriptionError] = useState('')

  const createAndAddPrescription = async (product: ProductResult) => {
    try {
      const response = await getProductDetails({
        id: product.productId,
        productType: product.productType,
      })

      if (!response) {
        console.error('No response returned')
        return
      }
      if (
        !isAbleToSelectS8Medicine &&
        response?.product.poisonClassification?.poisonClasses.includes('S8')
      ) {
        toast({
          title: 'Warning',
          description: 'Selection of S8 controlled substance is prohibited.',
          status: 'warning',
          duration: null,
          isClosable: true,
          position: 'top',
        })
        return
      }

      const item = detailsToPrescribedItem(response?.product)
      const isAbleToAdd = isAbleToAddNewPrescribedItem(item, prescribedItems, isPaperPrescription)
      const { passed, message } = preCheckProduct(item.productDetails)
      if (message) {
        toast({
          title: passed ? 'Warning' : 'Error',
          description: message,
          status: passed ? 'warning' : 'error',
          duration: null,
          isClosable: true,
          position: 'top',
        })
      }
      if (!passed) {
        return
      }

      if (isAbleToAdd) {
        dispatch(selectProduct(undefined))
        dispatch(resetProductResults())
        dispatch(setSearchTerm(''))
        setPrescriptionError('')
        return onAdd(item)
      }

      setPrescriptionError(ADD_PRESCRIBED_ITEM_FAILED_ERROR)
    } catch (e) {
      console.error(e)
    }
  }

  const searchAndSelectProduct = async (product: ProductResult) => {
    try {
      const response = await getProductDetails({
        id: product.productId,
        productType: product.productType,
      })
      if (
        !isAbleToSelectS8Medicine &&
        response?.product.poisonClassification?.poisonClasses.includes('S8')
      ) {
        toast({
          title: 'Warning',
          description: 'Selection of S8 controlled substance is prohibited.',
          status: 'warning',
          duration: null,
          isClosable: true,
          position: 'top',
        })
        return
      }

      return dispatch(selectProduct(response?.product))
    } catch (e) {
      console.error(e)
    }
  }

  const { trigger: searchProducts } = useApi(ProductSearchClient.searchProduct, {
    onSuccess: (response) => {
      setHasMoreDrugs(response.products?.length > 0)
      dispatch(setProductResults(response.products))
    },
  })

  const drugSearch = useMemo(
    () =>
      debounce((queryTerm: string) => {
        if (queryTerm.length >= 3) {
          searchProducts({
            queryTerm,
            ...DEFAULT_PAGE_INFO,
          })
        }
      }, 500),
    []
  )

  useEffect(() => {
    if (!selectedPrescribedItem) {
      setFormErrors(initFormError)
    }
  }, [selectedPrescribedItem, setFormErrors])

  const loadMoreDrugs = useCallback(() => {
    const { pageNumber, pageOffset } = pageInfo
    const newPageInfo = { pageNumber: pageNumber + 1, pageOffset }
    setPageInfo(newPageInfo)
    searchProducts({
      queryTerm: productSearchTerm,
      ...newPageInfo,
    })
  }, [pageInfo, productSearchTerm, searchProducts])

  return (
    <VStack alignItems="stretch">
      <Heading as="h4" size="md">
        Prescription Summary
      </Heading>
      <Card p="16px">
        <VStack gap="16px" alignItems="stretch">
          {scid ? <Text>SCID: {scid}</Text> : null}
          <FormControl isInvalid={!!prescriptionError}>
            <HStack justifyContent="start" alignItems="start">
              <FormLabel fontWeight="bold">Add Drugs</FormLabel>
              <Image src={MimsIntegrated} alt="Mims Integrated" width="100px" />
            </HStack>
            <Input
              bgColor="white"
              placeholder="Search Drugs"
              value={productSearchTerm}
              disabled={isDeactivatedPrescriber}
              onFocus={() => {
                setPrescriptionError('')
              }}
              onChange={(e) => {
                const queryTerm = e.target.value
                dispatch(resetProductResults())
                dispatch(setSearchTerm(queryTerm))
                drugSearch(queryTerm)
                setPageInfo(DEFAULT_PAGE_INFO)
                if (selectedProduct) dispatch(selectProduct(undefined))
              }}
            ></Input>
            {prescriptionError ? (
              <FormErrorMessage>{prescriptionError}</FormErrorMessage>
            ) : (
              <FormHelperText>{ADD_PRESCRIBED_ITEM_FAILED_ERROR}</FormHelperText>
            )}
          </FormControl>
          {productSearchTerm.length > 0 && (
            <DrugSearchResultsPanel
              hasMore={hasMoreDrugs}
              loadMoreResults={loadMoreDrugs}
              results={productResults}
              onAdd={async (product) => {
                await createAndAddPrescription(product)
              }}
              onSelect={async (product) => {
                toggleDrawer && toggleDrawer()
                await searchAndSelectProduct(product)
              }}
              selectedId={selectedProduct?.productId}
            />
          )}
          <CustomPrescriptionItemModal
            onSelect={(item) => {
              const customPrescriptionItem = customPrescriptionItemToPrescribedItem(item)
              dispatch(selectProduct(undefined))
              return onAdd(customPrescriptionItem)
            }}
            isOnlyPrivateMedicationAllowed={isOnlyPrivateMedicationAllowed}
          >
            {({ openModal }) => (
              <Box>
                <Button
                  colorScheme="blue"
                  onClick={openModal}
                  variant="outline"
                  disabled={isDeactivatedPrescriber}
                >
                  + Custom Preparation Items
                </Button>
              </Box>
            )}
          </CustomPrescriptionItemModal>
          <Card>
            <TableContainer>
              <Table variant="simple">
                <TableCaption>Drug Selected</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Drug Name</Th>
                    <Th isNumeric>Qty</Th>
                    <Th isNumeric>Rpts</Th>
                    <Th>PBS Status</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {prescribedItems.map((item, index) => {
                    return (
                      <PrescribedItemRow
                        displayName={item.displayName}
                        productName={item.tradeName}
                        itemState={item.state}
                        form={item.form}
                        quantity={item.quantity}
                        repeats={item.numberOfRepeatsAuthorised}
                        directions={item.patientInstructions}
                        pbsStatus={getPbsStatus(item)}
                        selected={item === selectedPrescribedItem}
                        onClick={() => {
                          toggleDrawer && toggleDrawer()
                          onSelect(item, index)
                        }}
                        onDelete={
                          onDelete
                            ? () => onDelete(index)
                            : () => dispatch(deletePrescribedItem(index))
                        }
                        key={item.productId}
                      />
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
          {selectedPrescribedItem && (
            <PrescribedItemDetails
              prescribedItem={selectedPrescribedItem}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              onChange={(updatedItem) => {
                const selectedItemIndex = prescribedItems.findIndex(
                  (item) => selectedPrescribedItem === item
                )
                if (selectedItemIndex !== undefined) {
                  onUpdate(updatedItem, selectedItemIndex)
                }
              }}
              onSelectPBSOrRPBS={() => toggleDrawer && toggleDrawer()}
              isOnlyPrivateMedicationAllowed={isOnlyPrivateMedicationAllowed}
            />
          )}
        </VStack>
      </Card>
    </VStack>
  )
}

export default PrescribedItemList
