import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  Checkbox,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import { useAppSelector } from '../../../app/typedReduxHooks'
import InputField from '../../../components/InputField'
import useApi from '../../../common/utils/api/useApi'
import { Prescriber } from '../../../types'
import { mapStatusToText } from '../../../utils/status/map-status-to-text'
import luhn_validate from '../../../utils/validation-helpers/LuhnValidate'
import { isValidPrescriberType } from '../../../utils/validation-helpers/PrescriberValidate'
import { PrescriberClient } from '../../NewScriptPage/ScriptForm/PrescriberSlice'
import { PRESCRIBER_OPRIONS } from '../constants'
import { TOOLTIP_TEXT } from '../../../constants'

export interface PrescriberWithOptionalId extends Omit<Prescriber, 'id'> {
  id?: string
}

interface PrescriberFormProps {
  register: UseFormRegister<Prescriber> | UseFormRegister<PrescriberWithOptionalId>
  setValue: UseFormSetValue<Prescriber> | UseFormSetValue<PrescriberWithOptionalId>
  setError: UseFormSetError<Prescriber> | UseFormSetError<PrescriberWithOptionalId>
  errors: FieldErrorsImpl<Prescriber> | FieldErrorsImpl<PrescriberWithOptionalId>
  getValues: UseFormGetValues<Prescriber> | UseFormGetValues<PrescriberWithOptionalId>
  setDisabledSubmit: (disabledSubmit: boolean) => void
  prescriber: Prescriber | null
}

const InputFieldWithStyles = chakra(InputField)

const ProfileForm = ({
  register,
  errors,
  setError,
  setValue,
  getValues,
  setDisabledSubmit,
  prescriber,
}: PrescriberFormProps) => {
  const { currentClinic } = useAppSelector((state) => state.clinic)
  const organization = useAppSelector((state) => state.organization?.data)
  const organizationSettings = organization?.settings
  const { trigger: checkHpii, loading } = useApi(PrescriberClient.checkHpii, {
    onSuccess: (response) => {
      setValue('hpiiStatus', response.hpii_status)
      if (response.hpii_status === 'A') {
        setDisabledSubmit(false)
      }
      if (response.hpii_status === 'RS') {
        setValue('hpii', response.hpii)
        checkHpii({
          hpii: response.hpii,
          family_name: getValues().familyName,
          hpio: currentClinic?.hpio_number || '',
        })
      }
      setValue('hpiiStatus', response.hpii_status)
    },
    onFailure: (error) => {
      const { message } = error
      console.error(`Failed to validate hpii, the main error is ${message}`)
      setError('hpiiStatus', { type: 'custom', message: 'HPII validation failed' })
      setValue('hpiiStatus', '')
    },
  })

  const validateHpii = async () => {
    const values = getValues()

    checkHpii({
      hpii: values.hpii,
      family_name: values.familyName,
      hpio: currentClinic?.hpio_number || '',
    })
  }

  const onBlur = (e: React.FormEvent<HTMLInputElement>, fieldName: keyof Prescriber) => {
    const { value } = e.target as HTMLInputElement
    if (value !== prescriber?.[fieldName]) {
      setDisabledSubmit(true)
    }
  }

  const invalidHpii = !!errors?.hpii?.message
  return (
    <>
      <SimpleGrid columns={[1, 2]} rowGap={6} columnGap={10} w="100%">
        {/* Name */}
        <InputField label="Given Names" field="givenNames" errors={errors} isRequired={true}>
          <Input
            placeholder="Given Names"
            {...register('givenNames', {
              required: 'Please fill your given name',
              maxLength: {
                value: 40,
                message: 'Prescriber name must be less than 40 Characters',
              },
            })}
          />
        </InputField>
        {/* Family Name */}
        <InputField label="Family Name" field="familyName" errors={errors} isRequired={true}>
          <Input
            placeholder="Family Name"
            {...register('familyName', {
              required: 'Please enter your family name',
              maxLength: {
                value: 40,
                message: 'Name less than 40 characters',
              },
              onBlur: (e) => onBlur(e, 'familyName'),
            })}
          ></Input>
        </InputField>

        <InputField
          label="Prescriber Number"
          field="prescriberNumber"
          errors={errors}
          isRequired={true}
        >
          <Input
            placeholder=""
            {...register('prescriberNumber', {
              required: 'Please enter a valid prescriber number',
            })}
          ></Input>
        </InputField>

        {/* Prescriber Type */}
        <InputField
          label="Prescriber Type"
          field="prescriberType"
          errors={errors}
          isRequired={true}
        >
          <Select
            placeholder="Prescriber Type"
            {...register('prescriberType', {
              required: 'Please enter a prescriber type',
              validate: (prescriberType: string) => {
                return (
                  isValidPrescriberType(String(prescriberType)) ||
                  'Please select a valid prescriber type'
                )
              },
            })}
          >
            {PRESCRIBER_OPRIONS.map(({ label, value }) => (
              <option value={value} key={label}>
                {label}
              </option>
            ))}
          </Select>
        </InputField>

        {/* Provider Number */}
        <InputField label="Provider Number" field="providerNumber" errors={errors}>
          <Input
            placeholder="Provider Number"
            {...register('providerNumber', {
              validate: (providerNumber?: string) => {
                if (!providerNumber) {
                  return true
                }
                const providerNumberStr = String(providerNumber)
                return (
                  /^[a-zA-Z0-9]*$/i.test(providerNumberStr.trim()) ||
                  'Please select a valid provider number'
                )
              },
            })}
          />
        </InputField>

        {/* AHPRA Number */}
        <InputField label="AHPRA Number" field="ahpraNumber" errors={errors}>
          <Input
            placeholder="AHPRA Number"
            {...register('ahpraNumber', {
              validate: (providerNumber?: string) => {
                if (!providerNumber) {
                  return true
                }
                const providerNumberStr = String(providerNumber)
                return (
                  /^[a-zA-Z0-9]*$/i.test(providerNumberStr.trim()) ||
                  'Please select a valid AHPRA number'
                )
              },
            })}
          />
        </InputField>

        {/* Entity ID */}
        <InputField label="Entity ID" field="entityId" errors={errors} isRequired={true}>
          <Input
            placeholder="Entity ID"
            {...register('entityId', {
              validate: (entityId?: string) => {
                if (!entityId) {
                  return true
                }
                const entityIDStr = String(entityId)
                return /^[0-9A-Z]{5}$/.test(entityIDStr.trim()) || 'Please select a valid entity ID'
              },
            })}
          />
        </InputField>

        {/* Qualifications */}
        <InputField label="Qualifications" field="qualifications" errors={errors} isRequired={true}>
          <Input
            placeholder="Please use commas to separate your qualification. e.g MBBS, DNB, FANZCA, FFPMANZCA, PG Dipl Clinical Research"
            {...register('qualifications', {
              maxLength: { value: 50, message: 'Qualifications must be less than 50 Characters' },
              required: 'Please enter qualifications',
            })}
          />
        </InputField>

        <InputField label="HPII Number" field="hpii" errors={errors} isRequired={true}>
          <Input
            placeholder="800361xxxxxxxxxx"
            {...register('hpii', {
              required: 'Please enter a HPII Number',
              validate: (hpii: number | string) => {
                hpii = String(hpii)
                return (
                  (hpii.startsWith('800361') && luhn_validate(hpii, 16)) ||
                  'Please Enter a valid HPII Number'
                )
              },
              onBlur: (e) => onBlur(e, 'hpii'),
            })}
          />
        </InputField>
        {organizationSettings?.enableRTPM && !organizationSettings?.forceEnableRTPM && (
          <VStack alignItems={'start'}>
            <Checkbox marginTop={['10px', '40px']} {...register('enableRtpm')}>
              Enable RTPM
            </Checkbox>
            <Text fontSize={'sm'} color={'red.500'}>
              * You must individually register for NDE before activating the functionality.
            </Text>
          </VStack>
        )}

        {/* HPII Status */}
        <InputFieldWithStyles
          label="HPII Status"
          field="hpiiStatus"
          errors={errors}
          gridColumn={['1/2', '1/3']}
        >
          <HStack spacing={10}>
            <Alert status={getValues().hpiiStatus === 'A' ? 'success' : 'warning'} flexBasis="50%">
              <AlertIcon />
              <Text>{mapStatusToText[getValues().hpiiStatus] ?? 'Invalid'}</Text>
            </Alert>

            <Button
              flexBasis="50%"
              onClick={validateHpii}
              disabled={loading || invalidHpii}
              isLoading={loading}
              padding={6}
            >
              Validate
            </Button>
          </HStack>
        </InputFieldWithStyles>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} gap={6} w="100%">
        {/* Email */}
        <InputField label="Email at Prescriber" field="email" errors={errors} isRequired={true}>
          <Input
            placeholder="example@email.ocm"
            {...register('email', {
              required: 'Please enter email for confirmation emails at this prescriber',
            })}
          ></Input>
        </InputField>
        {/* Phone Number */}
        <InputField label="Phone Number" field="phoneNumber" errors={errors}>
          <Input
            placeholder="eg. 0411222333"
            {...register('phoneNumber', {
              required: 'Please enter Phone for SMS confirmations at this prescriber',
              validate: (value?: string) => {
                if (!value) {
                  return true
                }
                return (
                  /^(0|\+61)[0-8]\d{8}$/g.test(value) ||
                  'Please provide a valid phone number (no space or bracket)'
                )
              },
            })}
          ></Input>
        </InputField>
        {/* Other Phone Number */}
        <InputField
          label="Other Phone Number"
          field="otherPhoneNumber"
          errors={errors}
          useTooltipIcon
          tooltipContent={TOOLTIP_TEXT.OTHER_PHONE_NUMBER}
        >
          <Input
            placeholder="eg. 0411222333"
            {...register('otherPhoneNumber', {
              validate: (value?: string) => {
                if (!value) {
                  return true
                }
                return (
                  /^(0|\+61)[0-8]\d{8}$/g.test(value) ||
                  'Please provide a valid phone number (no space or bracket)'
                )
              },
            })}
          />
        </InputField>
        {/* Fax Number */}
        <InputField label="Fax Number" field="faxNumber" errors={errors}>
          <Input
            placeholder="eg. 0386112233"
            {...register('faxNumber', {
              validate: (value?: string) => {
                if (!value) {
                  return true
                }
                return (
                  /^(0|\+61)[0-3|5-8]\d{8}$/g.test(value) ||
                  'Please provide a valid fax number (no space or bracket)'
                )
              },
            })}
          ></Input>
        </InputField>
      </SimpleGrid>
    </>
  )
}

export default ProfileForm
